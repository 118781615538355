<template>
  <section class="page-bg">
    <div class="banner">
<!--      <p>音声対話AIクラウドプラットフォーム</p>-->
    </div>
    <div class="services mobile_page-container">
      <div class="services-intro">
        <h3>音声認識</h3>
        <p>音声をテキストに変換するサービスは、ワンフレーズ認識、リアルタイム音声認識、録音ファイルの書き起こしなどのAPIを提供し、企業内部研修、官公庁会議など様々なシーンで幅広く活用できます。</p>
        <!-- <p class="pro-name">一般的なアプリケーション</p> -->
        <div>
          <div>
            <div>
              <p><img src="~@/assets/images/services_mobile/asr_1.png"></p>
              <span>リアルタイム<br>音声認識</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services_mobile/asr_2.png"></p>
              <span>録音ファイルの<br>書き起こし</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services tts mobile_page-container">
      <div class="services-intro">
        <h3>音声合成</h3>
        <p class="content-custom">文字を音声に変換するサービスです。短いテキストのオンライン合成、オフライン音声合成などのAPIを提供しており、高速かつ質の高い音声を合成し、人間と機械のコミュニケーションをより自然的にできます。</p>
        <!-- <p class="pro-name pro-name1">一般的なアプリケーション</p> -->
        <div>
          <div>
            <div class="p-custom">
              <p><img src="~@/assets/images/services_mobile/tts_1.png"></p>
              <span class="span-custom">短いテキストの<br>オンライン合成</span>
            </div>
            <div class="p-custom">
              <p><img src="~@/assets/images/services_mobile/tts_2.png"></p>
              <span class="span-custom">オフライン<br>音声合成</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dolphin mobile_page-container">
      <div class="services-intro">
        <h3>Dolphin大規模言語モデル</h3>
        <p>人間のメカニズムに基づき、言語の情報を言語学、心理学、脳神経科学と融合させ、大規模言語モデルに基づいて発話意図を深く理解、リアクションすることを実現。質問応答やコンテンツ作成などに幅広く活用できる。</p>
        <!-- <p class="pro-name">一般的なアプリケーション</p> -->
        <div>
          <div>
            <div>
              <p><img src="~@/assets/images/services_mobile/dolphin_1.png"></p>
              <span>内容生成</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services_mobile/dolphin_2.png"></p>
              <span>質問応答</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <h2 class=" mobile_page-container">製品の優位性</h2>
      <div class="adv-details">
        <div class="details-right">
          <el-timeline class="custom-timeline">
            <el-timeline-item id="firstLine" timestamp="多言語対応" placement="top">
              <el-card>
                <h4>日本語、英語、中国語、韓国語、フランス語、スペイン語、ロシア語、ドイツ語、チベット語、タイ語、ベトナム語など20以上の言語に対応しています。</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="secondLine" timestamp="カスタマ開発" placement="top">
              <el-card>
                <h4>顧客のニーズに応じてエンジンのカスタマイズチューニングサービスも提供できます。</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="thirdLine" timestamp="フルチェーン技術" placement="top">
              <el-card>
                <h4>音声認識、合成、LLMだけではなく、意味理解、テキスト生成、機械翻訳、声紋認識の音声対話フルチェーン独自技術持ってます。</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="forthLine" timestamp="On-Premise対応" placement="top">
              <el-card>
                <h4>お客様のセキュリティや複雑構成などの要望に合わせて、ご指定いただく環境でのOn-Premise構築のサービスも可能です。</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="contact1 mobile_page-container">
      <div class="contact-btn" @click="$router.push('/contact')">
        <img src="~@/assets/images/services_mobile/contactButton.png">
      </div>
      <div class="contact-left">
        <p>お問い合わせいただければ幸いです</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'dolphin-ai',
  mounted () {},
  methods: {
    pageScroll () {
      let imgs = document.getElementsByClassName('services-bg')
      for (let i = 0; i < imgs.length; i++) {
        let img1Top = imgs[i].getBoundingClientRect().top
        if (img1Top < 300) {
          imgs[i].classList.add("scroll-top")
          imgs[i].classList.remove("scroll-bottom")
        }
        if (img1Top < -715 || img1Top > 300) {
          imgs[i].classList.add("scroll-bottom")
          imgs[i].classList.remove("scroll-top")
        }
      }
      let list = document.getElementsByClassName('el-timeline-item__timestamp')
      let lis = document.getElementById('ulist').children
      for (let i = 0; i < list.length; i++) {
        let listHeight = list[i].getBoundingClientRect().top
        if (listHeight < 0 && listHeight > -400) {
          let content = list[i].innerText
          let index = this.ulist.findIndex(item => {return item === content})
          this.$refs.liImg.style.transform = `translateY(${lis[index].offsetTop}px)`
          this.$refs.liImg.classList.add('move-left')
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.banner {
  background: url('../assets/images/services_mobile/dolphin_banner.png') no-repeat left top;
  background-size: 100% 100%;
  height: 165px!important;
  display: flex;
  align-items: center;
  justify-content: left;
  >p {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 31.86px;
    color: rgba(255, 255, 255, 1);
    width: 255px;
    text-align: left;
    vertical-align: top;
    margin-left:30px;
  }
}
.dolphin-intro {
  padding-top: 24px;
  padding-bottom: 24px;
  h2 {
    left: 24px;
    top: 326px;
    width: 281px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 36px;
    color: rgba(47, 41, 223, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 12px;
  }
  p {
    left: 24px;
    top: 410px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
    color: rgba(60, 60, 60, 1);
    text-align: left;
    vertical-align: top;
  }
}
.services {
  height: 323px;
  background: url('../assets/images/services_mobile/services_bg.png') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  color: #fff;
}
.dolphin {
  height: 343px;
  background: url('../assets/images/services_mobile/services_bg1.png') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  color: #fff;
  padding-top:15.5px;
}
.tts {
  background: url('../assets/images/services_mobile/tts_bg.png') no-repeat left top;
  background-size: 100% 100%;
  color: #000;
  display: flex;
  align-items: center;
}
.services-intro {
  flex: 1;
  color: inherit;
  min-width: 302px;
  h3 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23.44px;
    color: inherit;
    text-align: left;
    vertical-align: top;
    margin-bottom: 18.5px;
  }
  >p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 30px;
  }
  .content-custom {
    color: #000;
  }
  .pro-name {
    background: url('../assets/images/services_mobile/point.png') no-repeat left 0;
    background-size: 18px 18px;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 16px;
  }
  .pro-name1 {
    background: url('../assets/images/services_mobile/point2.png') no-repeat left 0;
    background-size: 18px 18px;
    color: #000;
  }
  >div >div {
    display: flex;
    justify-content: space-between;
    >div {
      width: 146px;
      height: 56px;
      padding: 0 10px;
      border-radius: 27px;
      background: rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      p, p>img {
        width: 36px;
        height: 36px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
        padding-left: 12px;
      }
      .span-custom {
        color: rgba(0, 0, 0, 1);
      }
    }
    .p-custom {
      background: rgba(255, 255, 255, 1);
    }
  }
}
.services-bg {
  width: 357px;
  height: 535px;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 90px;
  border-radius: 28px;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.5);
  }
}
.services-bg.scroll-top img {
  transform: scale(1);
  transition: transform 0.5s linear;
}
.services-bg.scroll-bottom img {
  transform: scale(1.5);
  transition: transform 0.5s linear;
}
.tts .services-bg {
  width: 546px;
  height: 445px;
  position: relative;
  left: -108px;
}
.tts .services-intro >div >p {
  background-image: url('../assets/images/pro_name.png')
}
.advantage {
  min-height: 300px;
  padding-top: 40px;
  h2 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 28.13px;
    color: rgba(47, 41, 223, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 24px;
  }
  .adv-details {
    min-height: 100px;
    display: flex;
    padding-left: 35px;
    .details-left {
      width: 300px;
      height: 156px;
      display: flex;
      flex-shrink: 0;
      margin-right: 40px;
      margin-left: 10px;
      position: sticky;
      top: 213px;
      >p, >p img {
        width: 16px;
        height: 16px;
      }
      >p {
        height: 160px;
        margin-right: 12px;
        padding-top: 4px;
      }
      ul li {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.44px;
        color: rgba(60, 60, 60, 1);
        text-align: left;
        vertical-align: top;
        margin-bottom: 20px;
      }
      ul li:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    .move-left {
      transition: transform 0.5s linear;
    }
    .details-right {
      flex: 1;
    }
  }
}
.custom-timeline /deep/ .el-timeline-item {
  padding-left: 30px ;
}
.custom-timeline /deep/ .el-timeline-item__node {
  width: 18px;
  height: 18px;
  background: linear-gradient(225deg, rgba(128, 153, 255, 1) 0%, rgba(46, 41, 196, 1) 100%);
  left: -12px;
  margin-left: 7px ;
}
.custom-timeline /deep/ .el-timeline-item__wrapper{
  height: 130px;
  background: url('../assets/images/services_mobile/timeline.png') no-repeat left top;
  background-size: 100% 100%;
  position: relative;
  padding-left: 12px;
  top: -0.1875rem;
}

.custom-timeline li /deep/ .el-timeline-item__node:after {
  content: '01';
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 14.48px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: middle;
  margin-top:3px;
}
.custom-timeline li:nth-of-type(2) /deep/ .el-timeline-item__node:after {
  content: '02';
}
.custom-timeline li:nth-of-type(3) /deep/ .el-timeline-item__node:after {
  content: '03';
}
.custom-timeline li:nth-of-type(4) /deep/ .el-timeline-item__node:after {
  content: '04';
}
.custom-timeline-item__tail {
  border-left: 2px dashed rgba(63, 64, 208, 1);
}
.custom-timeline /deep/ .el-card.is-always-shadow {
  border: none;
  background-color: rgba(239, 235, 247, 1);
  box-shadow: none;
}
.custom-timeline /deep/ .el-timeline-item__timestamp {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 21.1px;
  color: rgba(60, 60, 60, 1);
  text-align: left;
  vertical-align: top;
  padding-left: 0px;
  padding-top:12px;
}
.custom-timeline /deep/ .el-card__body {
  padding:0px;
  background-color: rgba(255, 255, 255, 0.4);
}
.custom-timeline /deep/ .el-card__body h4 {
  width: 278px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 20px;
}
.custom-timeline /deep/ .el-card {
  overflow: visible;
}
.phs {
  height: 200px;
  width: 100%;
  position: relative;
}
</style>
